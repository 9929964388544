import { render, staticRenderFns } from "./TheMobileFooter.vue?vue&type=template&id=33a0a7db&scoped=true&ref=footer&"
import script from "./TheMobileFooter.vue?vue&type=script&lang=js&"
export * from "./TheMobileFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheMobileFooter.vue?vue&type=style&index=0&id=33a0a7db&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a0a7db",
  null
  
)

export default component.exports